<template>
  <apexchart type="radialBar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GaugeChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: Array,
    options: Object,
  }
}
</script>

<style scoped>

</style>

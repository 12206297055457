<template>
  <ValidationObserver ref="select-farm">
    <ValidationProvider name="Farm Name" rules="required" v-slot="{ errors }">
      <b-field label="Farm Name" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <b-autocomplete
          v-model="form.search_farm"
          :data="filteredFarm"
          clearable
          field="name"
          @select="handleChangeFarm">
          <template #empty>No results found</template>
        </b-autocomplete>
      </b-field>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "CreditScoringFarmName",
  data: () => {
    return {
      form: {
        search_farm: '',
      }
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),
    filteredFarm() {
      return this.farms.data.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_farm.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    ...mapActions('finance', [
      'checkFarmCycle',
    ]),

    async handleChangeFarm(option) {
      this.form.selected = option

      if (this.form.selected) {
        this.$loading()
        await this.checkFarmCycle(this.form.selected.farm_id).then(res => {
          this.form.data = res
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.$loading(false)
        })
      }

      this.canContinue()
    },
    async canContinue() {
      let status = false

      if (this.$refs['select-farm'])
        await this.$refs['select-farm'].validate().then(async (success) => {
          status = success
          this.$emit('update-data', {
            canContinue: success,
            data: this.form,
          })
        })

      return status
    },
  }
}
</script>

<style scoped>

</style>

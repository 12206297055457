var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"rekanan-mitra"},[_c('ValidationProvider',{attrs:{"name":"Supplier Pakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Supplier Pakan","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"feed_supplier","native-value":"3"},model:{value:(_vm.form.feed_supplier),callback:function ($$v) {_vm.$set(_vm.form, "feed_supplier", _vm._n($$v))},expression:"form.feed_supplier"}},[_vm._v(" Supplier pakan merupakan supplier yang terpercaya dari jaringan supplier pakan di Sgara ")]),_c('b-radio',{attrs:{"name":"feed_supplier","native-value":"1"},model:{value:(_vm.form.feed_supplier),callback:function ($$v) {_vm.$set(_vm.form, "feed_supplier", _vm._n($$v))},expression:"form.feed_supplier"}},[_vm._v(" Supplier pakan bukan dari jaringan supplier pakan di Sgara ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Pembeli Udang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pembeli Udang","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"shrimp_buyer","native-value":"3"},model:{value:(_vm.form.shrimp_buyer),callback:function ($$v) {_vm.$set(_vm.form, "shrimp_buyer", _vm._n($$v))},expression:"form.shrimp_buyer"}},[_vm._v(" Pembeli udang merupakan pembeli yang terpercaya dari jaringan pembeli udang di Sgara ")]),_c('b-radio',{attrs:{"name":"shrimp_buyer","native-value":"2"},model:{value:(_vm.form.shrimp_buyer),callback:function ($$v) {_vm.$set(_vm.form, "shrimp_buyer", _vm._n($$v))},expression:"form.shrimp_buyer"}},[_vm._v(" Pembeli udang bukan dari jaringan Sgara, tetapi telah mendapatkan referensi dari 3 petambak ")]),_c('b-radio',{attrs:{"name":"shrimp_buyer","native-value":"1"},model:{value:(_vm.form.shrimp_buyer),callback:function ($$v) {_vm.$set(_vm.form, "shrimp_buyer", _vm._n($$v))},expression:"form.shrimp_buyer"}},[_vm._v(" Pembeli udang tidak dikenal dan tidak memiliki referensi ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Supplier Benih","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Supplier Benih","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"hatch_supplier","native-value":"3"},model:{value:(_vm.form.hatch_supplier),callback:function ($$v) {_vm.$set(_vm.form, "hatch_supplier", _vm._n($$v))},expression:"form.hatch_supplier"}},[_vm._v(" Supplier benih merupakan supplier yang terpercaya dari jaringan supplier benih di Sgara ")]),_c('b-radio',{attrs:{"name":"hatch_supplier","native-value":"2"},model:{value:(_vm.form.hatch_supplier),callback:function ($$v) {_vm.$set(_vm.form, "hatch_supplier", _vm._n($$v))},expression:"form.hatch_supplier"}},[_vm._v(" Supplier benih bukan dari jaringan Sgara, tetapi telah mendapatkan referensi dari 3 petambak, dan dapat memenuhi standar benih dari Sgara ")]),_c('b-radio',{attrs:{"name":"hatch_supplier","native-value":"1"},model:{value:(_vm.form.hatch_supplier),callback:function ($$v) {_vm.$set(_vm.form, "hatch_supplier", _vm._n($$v))},expression:"form.hatch_supplier"}},[_vm._v(" Supplier benih tidak dikenal dan tidak memiliki referensi ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Pola Pembayaran Pembeli","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pola Pembayaran Pembeli","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"buyer_flow","native-value":"3"},model:{value:(_vm.form.buyer_flow),callback:function ($$v) {_vm.$set(_vm.form, "buyer_flow", _vm._n($$v))},expression:"form.buyer_flow"}},[_vm._v(" Seluruh pembeli udang yang sekarang dimiliki, membayar tunai langsung dan kurang dari 2 minggu ")]),_c('b-radio',{attrs:{"name":"buyer_flow","native-value":"2"},model:{value:(_vm.form.buyer_flow),callback:function ($$v) {_vm.$set(_vm.form, "buyer_flow", _vm._n($$v))},expression:"form.buyer_flow"}},[_vm._v(" Seluruh pembeli udang yang sekarang dimiliki, membayar antara 2 - 4 minggu ")]),_c('b-radio',{attrs:{"name":"buyer_flow","native-value":"1"},model:{value:(_vm.form.buyer_flow),callback:function ($$v) {_vm.$set(_vm.form, "buyer_flow", _vm._n($$v))},expression:"form.buyer_flow"}},[_vm._v(" Seluruh pembeli udang yang sekarang dimiliki, membayar diatas 4 minggu ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Piutang di Pembeli","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Piutang di Pembeli","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"account_receiveable","native-value":"3"},model:{value:(_vm.form.account_receiveable),callback:function ($$v) {_vm.$set(_vm.form, "account_receiveable", _vm._n($$v))},expression:"form.account_receiveable"}},[_vm._v(" Petani tidak memiliki piutang di pembeli udang ")]),_c('b-radio',{attrs:{"name":"account_receiveable","native-value":"2"},model:{value:(_vm.form.account_receiveable),callback:function ($$v) {_vm.$set(_vm.form, "account_receiveable", _vm._n($$v))},expression:"form.account_receiveable"}},[_vm._v(" Petani memiliki piutang di pembeli udang yang nilainya sebesar 20% dari pinjaman yang diminta ")]),_c('b-radio',{attrs:{"name":"account_receiveable","native-value":"1"},model:{value:(_vm.form.account_receiveable),callback:function ($$v) {_vm.$set(_vm.form, "account_receiveable", _vm._n($$v))},expression:"form.account_receiveable"}},[_vm._v(" Petani memiliki piutang di pembeli udang yang nilainya lebih besar dari pinjaman yang diminta ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Reputasi di Supplier Pakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Reputasi di Supplier Pakan","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"reputation","native-value":"3"},model:{value:(_vm.form.reputation),callback:function ($$v) {_vm.$set(_vm.form, "reputation", _vm._n($$v))},expression:"form.reputation"}},[_vm._v(" Tidak ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan ")]),_c('b-radio',{attrs:{"name":"reputation","native-value":"1"},model:{value:(_vm.form.reputation),callback:function ($$v) {_vm.$set(_vm.form, "reputation", _vm._n($$v))},expression:"form.reputation"}},[_vm._v(" Ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
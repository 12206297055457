<template>
  <ValidationObserver ref="eksternal">
    <ValidationProvider name="Bencana Alam" rules="required" v-slot="{ errors }">
      <b-field label="Bencana Alam" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.eksternal"
                   name="eksternal"
                   native-value="3">
            Tidak terjadi bencana sama sekali
          </b-radio>
          <b-radio v-model.number="form.eksternal"
                   name="eksternal"
                   native-value="2">
            Bencana terjadi setidaknya 1 kali dalam setahun
          </b-radio>
          <b-radio v-model.number="form.eksternal"
                   name="eksternal"
                   native-value="1">
            Bencana terjadi lebih dari 3 kali dalam setahun
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CreditScoringEksternal",
  data: () => {
    return {
      form: {}
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),
    filteredFarm() {
      return this.farms.data.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_farm.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    handleChangeFarm(option) {
      this.form.selected = option
      this.canContinue()
    },
    async canContinue() {
      let status = false

      if (this.$refs['eksternal'])
        await this.$refs['eksternal'].validate().then((success) => {
          status = success
          this.$emit('update-data', {
            canContinue: success,
            data: this.form,
          })
        })

      return status
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">

      <sg-tab v-model="activeTab"
              :items="['Mentored Farm','Credit Scoring']"
              class="border-top-0 mb-5"/>
      <finance-farm-table v-if="activeTab === 'Mentored Farm'"/>
      <finance-credit-scoring v-else-if="activeTab === 'Credit Scoring'"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import FinanceFarmTable from "@/components/Finance/FinanceFarmTable";
import SgTab from "@/components/Sg/SgTab";
import FinanceCreditScoring from "@/components/Finance/FinanceCreditScoring";

export default {
  name: 'FinanceFinancing',
  components: {
    FinanceCreditScoring,
    SgTab,
    FinanceFarmTable,
    TitleBar
  },
  data: () => {
    return {
      activeTab: 'Mentored Farm'
    }
  },
  computed: {
    titleStack() {
      return ['SgaraFinance ', 'Financing']
    }
  },
}
</script>

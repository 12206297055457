<template>
  <ValidationObserver ref="manajemen-bisnis">
    <ValidationProvider name="Pengalaman Budidaya" rules="required" v-slot="{ errors }">
      <b-field label="Pengalaman Budidaya" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis1"
                   name="experience"
                   native-value="3">
            Diatas 3 tahun
          </b-radio>
          <b-radio v-model.number="form.bisnis1"
                   name="experience"
                   native-value="2">
            Antara 1 - 3 tahun
          </b-radio>
          <b-radio v-model.number="form.bisnis1"
                   name="experience"
                   native-value="1">
            Dibawah 1 tahun
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Histori Kredit Formal" rules="required" v-slot="{ errors }">
      <b-field label="Histori Kredit Formal" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis2"
                   name="credit_history"
                   native-value="3">
            Pernah mendapatkan pinjaman produktif bank sebesar lebih dari Rp1 milyar
          </b-radio>
          <b-radio v-model.number="form.bisnis2"
                   name="credit_history"
                   native-value="2">
            Pernah mendapatkan pinjaman produktif dari bank sebesar kurang dari Rp. 1 Milyar
          </b-radio>
          <b-radio v-model.number="form.bisnis2"
                   name="credit_history"
                   native-value="1">
            Tidak pernah mendapatkan pinjaman produktif dari bank
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Asset or Revenue Growth" rules="required" v-slot="{ errors }">
      <b-field label="Asset or Revenue Growth" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis3"
                   name="revenue_growth"
                   native-value="3">
            Pendapatan dan kepemilikan aset produktif bertumbuh
          </b-radio>
          <b-radio v-model.number="form.bisnis3"
                   name="revenue_growth"
                   native-value="2">
            Pendapatan bertumbuh, tapi kepemilikan aset produktif tidak
          </b-radio>
          <b-radio v-model.number="form.bisnis3"
                   name="revenue_growth"
                   native-value="1">
            Pendapatan dan atau aset yang dimiliki tidak bertumbuh
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Loan to Revenue Ratio" rules="required" v-slot="{ errors }">
      <b-field label="Loan to Revenue Ratio" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis4"
                   name="revenue_ratio"
                   native-value="3">
            Kurang dari 60%
          </b-radio>
          <b-radio v-model.number="form.bisnis4"
                   name="revenue_ratio"
                   native-value="2">
            Antara 60% - 70%
          </b-radio>
          <b-radio v-model.number="form.bisnis4"
                   name="revenue_ratio"
                   native-value="1">
            Diatas 70%
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Loan to Collateral Value" rules="required" v-slot="{ errors }">
      <b-field label="Loan to Collateral Value" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis5"
                   name="collateral_value"
                   native-value="3">
            Kurang dari 25%
          </b-radio>
          <b-radio v-model.number="form.bisnis5"
                   name="collateral_value"
                   native-value="2">
            Antara 25% - 40%
          </b-radio>
          <b-radio v-model.number="form.bisnis5"
                   name="collateral_value"
                   native-value="1">
            Diatas 40%
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Persentase HPP" rules="required" v-slot="{ errors }">
      <b-field label="Persentase HPP" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis6"
                   name="hpp"
                   native-value="3">
            Kurang dari 60%
          </b-radio>
          <b-radio v-model.number="form.bisnis6"
                   name="hpp"
                   native-value="2">
            Antara 60% - 70%
          </b-radio>
          <b-radio v-model.number="form.bisnis6"
                   name="hpp"
                   native-value="1">
            Diatas 70%
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Credit History" rules="required" v-slot="{ errors }">
      <b-field label="Credit History" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis7"
                   name="credit_history"
                   native-value="3">
            Tidak pernah ada rekam jejak buruk (gagal bayar, terlambat bayar) untuk hutang di bank
            atau agen/sales pakan
          </b-radio>
          <b-radio v-model.number="form.bisnis7"
                   name="credit_history"
                   native-value="1">
            Pernah ada rekam jejak buruk (gagal bayar, terlambat bayar) untuk hutang di bank atau agen/sales pakan
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Financial Records" rules="required" v-slot="{ errors }">
      <b-field label="Financial Records" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis8"
                   name="financial_records"
                   native-value="3">
            Data tersedia untuk lebih dari 12 bulan sebelumnya
          </b-radio>
          <b-radio v-model.number="form.bisnis8"
                   name="financial_records"
                   native-value="2">
            Data tersedia, tpi hanya untuk 6 bulan sebelumnya
          </b-radio>
          <b-radio v-model.number="form.bisnis8"
                   name="financial_records"
                   native-value="1">
            Data tidak ada sama sekali
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Assets Ownership" rules="required" v-slot="{ errors }">
      <b-field label="Assets Ownership" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.bisnis9"
                   name="assets_ownership"
                   native-value="3">
            Aset kolam dan rumah pribadi milik sendiri
          </b-radio>
          <b-radio v-model.number="form.bisnis9"
                   name="assets_ownership"
                   native-value="2">
            Aset kolam berstatus sewa, tapi rumah pribadi milik sendiri
          </b-radio>
          <b-radio v-model.number="form.bisnis9"
                   name="assets_ownership"
                   native-value="1">
            Aset kolam, tanah, serta rumah pribadi berstatus sewa
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CreditScoringManajemenBisnis",
  data: () => {
    return {
      form: {}
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),
    filteredFarm() {
      return this.farms.data.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_farm.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    handleChangeFarm(option) {
      this.form.selected = option
      this.canContinue()
    },
    async canContinue() {
      let status = false

      if (this.$refs['manajemen-bisnis'])
        await this.$refs['manajemen-bisnis'].validate().then((success) => {
          status = success
          this.$emit('update-data', {
            canContinue: success,
            data: this.form,
          })
        })

      return status
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <ValidationObserver ref="budidaya">
    <ValidationProvider name="Panen" rules="required" v-slot="{ errors }">
      <b-field label="Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya1"
                   name="panen"
                   native-value="3">
            diatas 25 ton/ha
          </b-radio>
          <b-radio v-model.number="form.budidaya1"
                   name="panen"
                   native-value="2">
            antara 15 - 25 ton/ha
          </b-radio>
          <b-radio v-model.number="form.budidaya1"
                   name="panen"
                   native-value="1">
            dibawah 15 ton/ha
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="FCR" rules="required" v-slot="{ errors }">
      <b-field label="FCR" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya2"
                   name="fcr"
                   native-value="3">
            FCR dibawah 1.5
          </b-radio>
          <b-radio v-model.number="form.budidaya2"
                   name="fcr"
                   native-value="2">
            FCR antara 1.5 - 1.7
          </b-radio>
          <b-radio v-model.number="form.budidaya2"
                   name="fcr"
                   native-value="1">
            FCR diatas 1.7
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Waktu Panen" rules="required" v-slot="{ errors }">
      <b-field label="Waktu Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-datepicker
            icon="calendar-text"
            locale="id-ID"
            v-model="form.harvest_date">
          </b-datepicker>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Survival Rate" rules="required" v-slot="{ errors }">
      <b-field label="Survival Rate" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya3"
                   name="sr"
                   native-value="3">
            SR diatas 90%
          </b-radio>
          <b-radio v-model.number="form.budidaya3"
                   name="sr"
                   native-value="2">
            SR antara 70% - 90%
          </b-radio>
          <b-radio v-model.number="form.budidaya3"
                   name="sr"
                   native-value="1">
            SR dibawah 70%
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Pakan yang digunakan" rules="required" v-slot="{ errors }">
      <b-field label="Pakan yang digunakan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya4"
                   name="feed_used"
                   native-value="3">
            Menggunakan 100% pakan pabrik
          </b-radio>
          <b-radio v-model.number="form.budidaya4"
                   name="feed_used"
                   native-value="2">
            Menggunakan campuran pakan alternative dan pakan pabrik
          </b-radio>
          <b-radio v-model.number="form.budidaya4"
                   name="feed_used"
                   native-value="1">
            Menggunakan 100% pakan alternatif
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Prosedur Budidaya" rules="required" v-slot="{ errors }">
      <b-field label="Prosedur Budidaya" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-checkbox class="mb-3" v-model="sop.sampling" disabled>
            Sampling
          </b-checkbox>
          <b-checkbox class="mb-3" v-model="sop.stock_harvest" disabled>
            Stock & Harvest
          </b-checkbox>
          <b-checkbox class="mb-3" v-model="sop.warehouse" disabled>
            Warehouse & Use Item
          </b-checkbox>
          <b-checkbox class="mb-3" v-model="sop.water_quality" disabled>
            Water Quality
          </b-checkbox>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Pencatatan Data" rules="required" v-slot="{ errors }">
      <b-field label="Pencatatan Data" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya6"
                   name="pencatatan_data"
                   native-value="3">
            Mencatat data budidaya dan tersedia lebih dari 1 siklus sebelumnya
          </b-radio>
          <b-radio v-model.number="form.budidaya6"
                   name="pencatatan_data"
                   native-value="2">
            Mencatat data budidaya tapi hanya 1 siklus terakhir
          </b-radio>
          <b-radio v-model.number="form.budidaya6"
                   name="pencatatan_data"
                   native-value="1">
            Tidak mencatat data budidaya
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Tipe Kolam" rules="required" v-slot="{ errors }">
      <b-field label="Tipe Kolam" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya7"
                   name="tipe_kolam"
                   native-value="3">
            HDPE
          </b-radio>
          <b-radio v-model.number="form.budidaya7"
                   name="tipe_kolam"
                   native-value="2">
            Beton
          </b-radio>
          <b-radio v-model.number="form.budidaya7"
                   name="tipe_kolam"
                   native-value="1">
            Tanah
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Kincir" rules="required" v-slot="{ errors }">
      <b-field label="Kincir" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya8"
                   name="kincir"
                   native-value="3">
            Dibawah 500 kg / 1 hp kincir
          </b-radio>
          <b-radio v-model.number="form.budidaya8"
                   name="kincir"
                   native-value="2">
            Antara 500 - 600 kg / 1 hp kincir
          </b-radio>
          <b-radio v-model.number="form.budidaya8"
                   name="kincir"
                   native-value="1">
            Diatas 600 kg / 1 hp kincir
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Autofeeder" rules="required" v-slot="{ errors }">
      <b-field label="Autofeeder" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya9"
                   name="autofeeder"
                   native-value="3">
            1 autofeeder per 1000 m2
          </b-radio>
          <b-radio v-model.number="form.budidaya9"
                   name="autofeeder"
                   native-value="2">
            1 autofeeder per 1500 m2
          </b-radio>
          <b-radio v-model.number="form.budidaya9"
                   name="autofeeder"
                   native-value="1">
            1 autofeeder diatas 1500 m2
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Infrastruktur dan Aset Pendukung Bisnis" rules="required" v-slot="{ errors }">
      <b-field label="Infrastruktur dan Aset Pendukung Bisnis" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.budidaya10"
                   name="infrastruktur"
                   native-value="3">
            Tersedia infrastruktur langsung maupun tidak langsung
          </b-radio>
          <b-radio v-model.number="form.budidaya10"
                   name="infrastruktur"
                   native-value="2">
            Tersedia infrastruktur langsung, tapi tidak tersedia infrastruktur tidak langsung. Atau sebaliknya.
          </b-radio>
          <b-radio v-model.number="form.budidaya10"
                   name="infrastruktur"
                   native-value="1">
            Tidak tersedia infrastruktur pendukung sama sekali
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CreditScoringBudidaya",
  props: {
    data: Array,
  },
  data: () => {
    return {
      sop: {
        sampling: false,
        stock_harvest: false,
        validation_score: 0,
        warehouse: false,
        water_quality: false,
      },
      form: {
        budidaya1: null,
        budidaya2: null,
        budidaya3: null,
        budidaya4: null,
        budidaya5: null,
        budidaya6: null,
        budidaya7: null,
        budidaya8: null,
        budidaya9: null,
        budidaya10: null,
      }
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),
    filteredFarm() {
      return this.farms.data.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_farm.toLowerCase()) >= 0
        )
      })
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        console.log(this.data)
        let harvest = this.data.find(e => e.type === 'harvest-cycle')
        if (harvest) {
          if (harvest.data.total > 25) this.form.budidaya1 = 3
          else if (harvest.data.total >= 15 && harvest.data.total <= 25) this.form.budidaya1 = 2
          else if (harvest.data.total < 15) this.form.budidaya1 = 1
        }
        let fcrSr = this.data.find(e => e.type === 'fcr-sr')
        if (fcrSr) {
          if (fcrSr.data.fcr < 1.5) this.form.budidaya2 = 3
          else if (fcrSr.data.fcr >= 1.5 && fcrSr.data.fcr <= 1.7) this.form.budidaya2 = 2
          else if (fcrSr.data.fcr > 1.7) this.form.budidaya2 = 1
          if (fcrSr.data.sr > 90) this.form.budidaya3 = 3
          else if (fcrSr.data.sr >= 70 && fcrSr.data.sr <= 90) this.form.budidaya3 = 2
          else if (fcrSr.data.sr < 70) this.form.budidaya3 = 1
        }
        let sop = this.data.find(e => e.type === 'sop')
        if (sop) {
          this.sop = sop.data
          this.form.budidaya5 = this.sop.validation_score * 3 / 100
        }
        let dataRecording = this.data.find(e => e.type === 'data-recording')
        if (dataRecording) {
          if (dataRecording.data.total > 1) this.form.budidaya6 = 3
          else if (dataRecording.data.total === 1) this.form.budidaya6 = 2
          else this.form.budidaya6 = 1
        }
      },
    },
  },
  methods: {
    async canContinue() {
      let status = false

      if (this.$refs['budidaya'])
        await this.$refs['budidaya'].validate().then((success) => {
          status = success
          this.$emit('update-data', {
            canContinue: success,
            data: this.form,
          })
        })

      return status
    },
  }
}
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"manajemen-bisnis"},[_c('ValidationProvider',{attrs:{"name":"Pengalaman Budidaya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pengalaman Budidaya","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"experience","native-value":"3"},model:{value:(_vm.form.bisnis1),callback:function ($$v) {_vm.$set(_vm.form, "bisnis1", _vm._n($$v))},expression:"form.bisnis1"}},[_vm._v(" Diatas 3 tahun ")]),_c('b-radio',{attrs:{"name":"experience","native-value":"2"},model:{value:(_vm.form.bisnis1),callback:function ($$v) {_vm.$set(_vm.form, "bisnis1", _vm._n($$v))},expression:"form.bisnis1"}},[_vm._v(" Antara 1 - 3 tahun ")]),_c('b-radio',{attrs:{"name":"experience","native-value":"1"},model:{value:(_vm.form.bisnis1),callback:function ($$v) {_vm.$set(_vm.form, "bisnis1", _vm._n($$v))},expression:"form.bisnis1"}},[_vm._v(" Dibawah 1 tahun ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Histori Kredit Formal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Histori Kredit Formal","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"credit_history","native-value":"3"},model:{value:(_vm.form.bisnis2),callback:function ($$v) {_vm.$set(_vm.form, "bisnis2", _vm._n($$v))},expression:"form.bisnis2"}},[_vm._v(" Pernah mendapatkan pinjaman produktif bank sebesar lebih dari Rp1 milyar ")]),_c('b-radio',{attrs:{"name":"credit_history","native-value":"2"},model:{value:(_vm.form.bisnis2),callback:function ($$v) {_vm.$set(_vm.form, "bisnis2", _vm._n($$v))},expression:"form.bisnis2"}},[_vm._v(" Pernah mendapatkan pinjaman produktif dari bank sebesar kurang dari Rp. 1 Milyar ")]),_c('b-radio',{attrs:{"name":"credit_history","native-value":"1"},model:{value:(_vm.form.bisnis2),callback:function ($$v) {_vm.$set(_vm.form, "bisnis2", _vm._n($$v))},expression:"form.bisnis2"}},[_vm._v(" Tidak pernah mendapatkan pinjaman produktif dari bank ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Asset or Revenue Growth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Asset or Revenue Growth","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"revenue_growth","native-value":"3"},model:{value:(_vm.form.bisnis3),callback:function ($$v) {_vm.$set(_vm.form, "bisnis3", _vm._n($$v))},expression:"form.bisnis3"}},[_vm._v(" Pendapatan dan kepemilikan aset produktif bertumbuh ")]),_c('b-radio',{attrs:{"name":"revenue_growth","native-value":"2"},model:{value:(_vm.form.bisnis3),callback:function ($$v) {_vm.$set(_vm.form, "bisnis3", _vm._n($$v))},expression:"form.bisnis3"}},[_vm._v(" Pendapatan bertumbuh, tapi kepemilikan aset produktif tidak ")]),_c('b-radio',{attrs:{"name":"revenue_growth","native-value":"1"},model:{value:(_vm.form.bisnis3),callback:function ($$v) {_vm.$set(_vm.form, "bisnis3", _vm._n($$v))},expression:"form.bisnis3"}},[_vm._v(" Pendapatan dan atau aset yang dimiliki tidak bertumbuh ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Loan to Revenue Ratio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Loan to Revenue Ratio","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"revenue_ratio","native-value":"3"},model:{value:(_vm.form.bisnis4),callback:function ($$v) {_vm.$set(_vm.form, "bisnis4", _vm._n($$v))},expression:"form.bisnis4"}},[_vm._v(" Kurang dari 60% ")]),_c('b-radio',{attrs:{"name":"revenue_ratio","native-value":"2"},model:{value:(_vm.form.bisnis4),callback:function ($$v) {_vm.$set(_vm.form, "bisnis4", _vm._n($$v))},expression:"form.bisnis4"}},[_vm._v(" Antara 60% - 70% ")]),_c('b-radio',{attrs:{"name":"revenue_ratio","native-value":"1"},model:{value:(_vm.form.bisnis4),callback:function ($$v) {_vm.$set(_vm.form, "bisnis4", _vm._n($$v))},expression:"form.bisnis4"}},[_vm._v(" Diatas 70% ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Loan to Collateral Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Loan to Collateral Value","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"collateral_value","native-value":"3"},model:{value:(_vm.form.bisnis5),callback:function ($$v) {_vm.$set(_vm.form, "bisnis5", _vm._n($$v))},expression:"form.bisnis5"}},[_vm._v(" Kurang dari 25% ")]),_c('b-radio',{attrs:{"name":"collateral_value","native-value":"2"},model:{value:(_vm.form.bisnis5),callback:function ($$v) {_vm.$set(_vm.form, "bisnis5", _vm._n($$v))},expression:"form.bisnis5"}},[_vm._v(" Antara 25% - 40% ")]),_c('b-radio',{attrs:{"name":"collateral_value","native-value":"1"},model:{value:(_vm.form.bisnis5),callback:function ($$v) {_vm.$set(_vm.form, "bisnis5", _vm._n($$v))},expression:"form.bisnis5"}},[_vm._v(" Diatas 40% ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Persentase HPP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Persentase HPP","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"hpp","native-value":"3"},model:{value:(_vm.form.bisnis6),callback:function ($$v) {_vm.$set(_vm.form, "bisnis6", _vm._n($$v))},expression:"form.bisnis6"}},[_vm._v(" Kurang dari 60% ")]),_c('b-radio',{attrs:{"name":"hpp","native-value":"2"},model:{value:(_vm.form.bisnis6),callback:function ($$v) {_vm.$set(_vm.form, "bisnis6", _vm._n($$v))},expression:"form.bisnis6"}},[_vm._v(" Antara 60% - 70% ")]),_c('b-radio',{attrs:{"name":"hpp","native-value":"1"},model:{value:(_vm.form.bisnis6),callback:function ($$v) {_vm.$set(_vm.form, "bisnis6", _vm._n($$v))},expression:"form.bisnis6"}},[_vm._v(" Diatas 70% ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Credit History","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Credit History","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"credit_history","native-value":"3"},model:{value:(_vm.form.bisnis7),callback:function ($$v) {_vm.$set(_vm.form, "bisnis7", _vm._n($$v))},expression:"form.bisnis7"}},[_vm._v(" Tidak pernah ada rekam jejak buruk (gagal bayar, terlambat bayar) untuk hutang di bank atau agen/sales pakan ")]),_c('b-radio',{attrs:{"name":"credit_history","native-value":"1"},model:{value:(_vm.form.bisnis7),callback:function ($$v) {_vm.$set(_vm.form, "bisnis7", _vm._n($$v))},expression:"form.bisnis7"}},[_vm._v(" Pernah ada rekam jejak buruk (gagal bayar, terlambat bayar) untuk hutang di bank atau agen/sales pakan ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Financial Records","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Financial Records","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"financial_records","native-value":"3"},model:{value:(_vm.form.bisnis8),callback:function ($$v) {_vm.$set(_vm.form, "bisnis8", _vm._n($$v))},expression:"form.bisnis8"}},[_vm._v(" Data tersedia untuk lebih dari 12 bulan sebelumnya ")]),_c('b-radio',{attrs:{"name":"financial_records","native-value":"2"},model:{value:(_vm.form.bisnis8),callback:function ($$v) {_vm.$set(_vm.form, "bisnis8", _vm._n($$v))},expression:"form.bisnis8"}},[_vm._v(" Data tersedia, tpi hanya untuk 6 bulan sebelumnya ")]),_c('b-radio',{attrs:{"name":"financial_records","native-value":"1"},model:{value:(_vm.form.bisnis8),callback:function ($$v) {_vm.$set(_vm.form, "bisnis8", _vm._n($$v))},expression:"form.bisnis8"}},[_vm._v(" Data tidak ada sama sekali ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Assets Ownership","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Assets Ownership","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"assets_ownership","native-value":"3"},model:{value:(_vm.form.bisnis9),callback:function ($$v) {_vm.$set(_vm.form, "bisnis9", _vm._n($$v))},expression:"form.bisnis9"}},[_vm._v(" Aset kolam dan rumah pribadi milik sendiri ")]),_c('b-radio',{attrs:{"name":"assets_ownership","native-value":"2"},model:{value:(_vm.form.bisnis9),callback:function ($$v) {_vm.$set(_vm.form, "bisnis9", _vm._n($$v))},expression:"form.bisnis9"}},[_vm._v(" Aset kolam berstatus sewa, tapi rumah pribadi milik sendiri ")]),_c('b-radio',{attrs:{"name":"assets_ownership","native-value":"1"},model:{value:(_vm.form.bisnis9),callback:function ($$v) {_vm.$set(_vm.form, "bisnis9", _vm._n($$v))},expression:"form.bisnis9"}},[_vm._v(" Aset kolam, tanah, serta rumah pribadi berstatus sewa ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
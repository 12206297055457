<template>
  <ValidationObserver ref="rekanan-mitra">
    <ValidationProvider name="Supplier Pakan" rules="required" v-slot="{ errors }">
      <b-field label="Supplier Pakan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.feed_supplier"
                   name="feed_supplier"
                   native-value="3">
            Supplier pakan merupakan supplier yang terpercaya dari jaringan supplier pakan di Sgara
          </b-radio>
          <b-radio v-model.number="form.feed_supplier"
                   name="feed_supplier"
                   native-value="1">
            Supplier pakan bukan dari jaringan supplier pakan di Sgara
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Pembeli Udang" rules="required" v-slot="{ errors }">
      <b-field label="Pembeli Udang" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.shrimp_buyer"
                   name="shrimp_buyer"
                   native-value="3">
            Pembeli udang merupakan pembeli yang terpercaya dari jaringan pembeli udang di Sgara
          </b-radio>
          <b-radio v-model.number="form.shrimp_buyer"
                   name="shrimp_buyer"
                   native-value="2">
            Pembeli udang bukan dari jaringan Sgara, tetapi telah mendapatkan referensi dari 3 petambak
          </b-radio>
          <b-radio v-model.number="form.shrimp_buyer"
                   name="shrimp_buyer"
                   native-value="1">
            Pembeli udang tidak dikenal dan tidak memiliki referensi
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Supplier Benih" rules="required" v-slot="{ errors }">
      <b-field label="Supplier Benih" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.hatch_supplier"
                   name="hatch_supplier"
                   native-value="3">
            Supplier benih merupakan supplier yang terpercaya dari jaringan supplier benih di Sgara
          </b-radio>
          <b-radio v-model.number="form.hatch_supplier"
                   name="hatch_supplier"
                   native-value="2">
            Supplier benih bukan dari jaringan Sgara, tetapi telah mendapatkan referensi dari 3 petambak, dan dapat
            memenuhi standar benih dari Sgara
          </b-radio>
          <b-radio v-model.number="form.hatch_supplier"
                   name="hatch_supplier"
                   native-value="1">
            Supplier benih tidak dikenal dan tidak memiliki referensi
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Pola Pembayaran Pembeli" rules="required" v-slot="{ errors }">
      <b-field label="Pola Pembayaran Pembeli" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.buyer_flow"
                   name="buyer_flow"
                   native-value="3">
            Seluruh pembeli udang yang sekarang dimiliki, membayar tunai langsung dan kurang dari 2 minggu
          </b-radio>
          <b-radio v-model.number="form.buyer_flow"
                   name="buyer_flow"
                   native-value="2">
            Seluruh pembeli udang yang sekarang dimiliki, membayar antara 2 - 4 minggu
          </b-radio>
          <b-radio v-model.number="form.buyer_flow"
                   name="buyer_flow"
                   native-value="1">
            Seluruh pembeli udang yang sekarang dimiliki, membayar diatas 4 minggu
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Piutang di Pembeli" rules="required" v-slot="{ errors }">
      <b-field label="Piutang di Pembeli" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.account_receiveable"
                   name="account_receiveable"
                   native-value="3">
            Petani tidak memiliki piutang di pembeli udang
          </b-radio>
          <b-radio v-model.number="form.account_receiveable"
                   name="account_receiveable"
                   native-value="2">
            Petani memiliki piutang di pembeli udang yang nilainya sebesar 20% dari pinjaman yang diminta
          </b-radio>
          <b-radio v-model.number="form.account_receiveable"
                   name="account_receiveable"
                   native-value="1">
            Petani memiliki piutang di pembeli udang yang nilainya lebih besar dari pinjaman yang diminta
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
    <ValidationProvider name="Reputasi di Supplier Pakan" rules="required" v-slot="{ errors }">
      <b-field label="Reputasi di Supplier Pakan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
        <div class="is-flex is-flex-direction-column">
          <b-radio v-model.number="form.reputation"
                   name="reputation"
                   native-value="3">
            Tidak ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan
          </b-radio>
          <b-radio v-model.number="form.reputation"
                   name="reputation"
                   native-value="1">
            Ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan
          </b-radio>
        </div>
      </b-field>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CreditScoringRekananMitra",
  data: () => {
    return {
      form: {}
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),
    filteredFarm() {
      return this.farms.data.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_farm.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    handleChangeFarm(option) {
      this.form.selected = option
      this.canContinue()
    },
    async canContinue() {
      let status = false

      if (this.$refs['rekanan-mitra'])
        await this.$refs['rekanan-mitra'].validate().then((success) => {
          status = success
          this.$emit('update-data', {
            canContinue: success,
            data: this.form,
          })
        })

      return status
    },
  }
}
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"budidaya"},[_c('ValidationProvider',{attrs:{"name":"Panen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Panen","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"panen","native-value":"3"},model:{value:(_vm.form.budidaya1),callback:function ($$v) {_vm.$set(_vm.form, "budidaya1", _vm._n($$v))},expression:"form.budidaya1"}},[_vm._v(" diatas 25 ton/ha ")]),_c('b-radio',{attrs:{"name":"panen","native-value":"2"},model:{value:(_vm.form.budidaya1),callback:function ($$v) {_vm.$set(_vm.form, "budidaya1", _vm._n($$v))},expression:"form.budidaya1"}},[_vm._v(" antara 15 - 25 ton/ha ")]),_c('b-radio',{attrs:{"name":"panen","native-value":"1"},model:{value:(_vm.form.budidaya1),callback:function ($$v) {_vm.$set(_vm.form, "budidaya1", _vm._n($$v))},expression:"form.budidaya1"}},[_vm._v(" dibawah 15 ton/ha ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"FCR","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"FCR","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"fcr","native-value":"3"},model:{value:(_vm.form.budidaya2),callback:function ($$v) {_vm.$set(_vm.form, "budidaya2", _vm._n($$v))},expression:"form.budidaya2"}},[_vm._v(" FCR dibawah 1.5 ")]),_c('b-radio',{attrs:{"name":"fcr","native-value":"2"},model:{value:(_vm.form.budidaya2),callback:function ($$v) {_vm.$set(_vm.form, "budidaya2", _vm._n($$v))},expression:"form.budidaya2"}},[_vm._v(" FCR antara 1.5 - 1.7 ")]),_c('b-radio',{attrs:{"name":"fcr","native-value":"1"},model:{value:(_vm.form.budidaya2),callback:function ($$v) {_vm.$set(_vm.form, "budidaya2", _vm._n($$v))},expression:"form.budidaya2"}},[_vm._v(" FCR diatas 1.7 ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Waktu Panen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Waktu Panen","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-datepicker',{attrs:{"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.harvest_date),callback:function ($$v) {_vm.$set(_vm.form, "harvest_date", $$v)},expression:"form.harvest_date"}})],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Survival Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Survival Rate","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"sr","native-value":"3"},model:{value:(_vm.form.budidaya3),callback:function ($$v) {_vm.$set(_vm.form, "budidaya3", _vm._n($$v))},expression:"form.budidaya3"}},[_vm._v(" SR diatas 90% ")]),_c('b-radio',{attrs:{"name":"sr","native-value":"2"},model:{value:(_vm.form.budidaya3),callback:function ($$v) {_vm.$set(_vm.form, "budidaya3", _vm._n($$v))},expression:"form.budidaya3"}},[_vm._v(" SR antara 70% - 90% ")]),_c('b-radio',{attrs:{"name":"sr","native-value":"1"},model:{value:(_vm.form.budidaya3),callback:function ($$v) {_vm.$set(_vm.form, "budidaya3", _vm._n($$v))},expression:"form.budidaya3"}},[_vm._v(" SR dibawah 70% ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Pakan yang digunakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pakan yang digunakan","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"feed_used","native-value":"3"},model:{value:(_vm.form.budidaya4),callback:function ($$v) {_vm.$set(_vm.form, "budidaya4", _vm._n($$v))},expression:"form.budidaya4"}},[_vm._v(" Menggunakan 100% pakan pabrik ")]),_c('b-radio',{attrs:{"name":"feed_used","native-value":"2"},model:{value:(_vm.form.budidaya4),callback:function ($$v) {_vm.$set(_vm.form, "budidaya4", _vm._n($$v))},expression:"form.budidaya4"}},[_vm._v(" Menggunakan campuran pakan alternative dan pakan pabrik ")]),_c('b-radio',{attrs:{"name":"feed_used","native-value":"1"},model:{value:(_vm.form.budidaya4),callback:function ($$v) {_vm.$set(_vm.form, "budidaya4", _vm._n($$v))},expression:"form.budidaya4"}},[_vm._v(" Menggunakan 100% pakan alternatif ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Prosedur Budidaya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Prosedur Budidaya","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-checkbox',{staticClass:"mb-3",attrs:{"disabled":""},model:{value:(_vm.sop.sampling),callback:function ($$v) {_vm.$set(_vm.sop, "sampling", $$v)},expression:"sop.sampling"}},[_vm._v(" Sampling ")]),_c('b-checkbox',{staticClass:"mb-3",attrs:{"disabled":""},model:{value:(_vm.sop.stock_harvest),callback:function ($$v) {_vm.$set(_vm.sop, "stock_harvest", $$v)},expression:"sop.stock_harvest"}},[_vm._v(" Stock & Harvest ")]),_c('b-checkbox',{staticClass:"mb-3",attrs:{"disabled":""},model:{value:(_vm.sop.warehouse),callback:function ($$v) {_vm.$set(_vm.sop, "warehouse", $$v)},expression:"sop.warehouse"}},[_vm._v(" Warehouse & Use Item ")]),_c('b-checkbox',{staticClass:"mb-3",attrs:{"disabled":""},model:{value:(_vm.sop.water_quality),callback:function ($$v) {_vm.$set(_vm.sop, "water_quality", $$v)},expression:"sop.water_quality"}},[_vm._v(" Water Quality ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Pencatatan Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pencatatan Data","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"pencatatan_data","native-value":"3"},model:{value:(_vm.form.budidaya6),callback:function ($$v) {_vm.$set(_vm.form, "budidaya6", _vm._n($$v))},expression:"form.budidaya6"}},[_vm._v(" Mencatat data budidaya dan tersedia lebih dari 1 siklus sebelumnya ")]),_c('b-radio',{attrs:{"name":"pencatatan_data","native-value":"2"},model:{value:(_vm.form.budidaya6),callback:function ($$v) {_vm.$set(_vm.form, "budidaya6", _vm._n($$v))},expression:"form.budidaya6"}},[_vm._v(" Mencatat data budidaya tapi hanya 1 siklus terakhir ")]),_c('b-radio',{attrs:{"name":"pencatatan_data","native-value":"1"},model:{value:(_vm.form.budidaya6),callback:function ($$v) {_vm.$set(_vm.form, "budidaya6", _vm._n($$v))},expression:"form.budidaya6"}},[_vm._v(" Tidak mencatat data budidaya ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Tipe Kolam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tipe Kolam","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"tipe_kolam","native-value":"3"},model:{value:(_vm.form.budidaya7),callback:function ($$v) {_vm.$set(_vm.form, "budidaya7", _vm._n($$v))},expression:"form.budidaya7"}},[_vm._v(" HDPE ")]),_c('b-radio',{attrs:{"name":"tipe_kolam","native-value":"2"},model:{value:(_vm.form.budidaya7),callback:function ($$v) {_vm.$set(_vm.form, "budidaya7", _vm._n($$v))},expression:"form.budidaya7"}},[_vm._v(" Beton ")]),_c('b-radio',{attrs:{"name":"tipe_kolam","native-value":"1"},model:{value:(_vm.form.budidaya7),callback:function ($$v) {_vm.$set(_vm.form, "budidaya7", _vm._n($$v))},expression:"form.budidaya7"}},[_vm._v(" Tanah ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Kincir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Kincir","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"kincir","native-value":"3"},model:{value:(_vm.form.budidaya8),callback:function ($$v) {_vm.$set(_vm.form, "budidaya8", _vm._n($$v))},expression:"form.budidaya8"}},[_vm._v(" Dibawah 500 kg / 1 hp kincir ")]),_c('b-radio',{attrs:{"name":"kincir","native-value":"2"},model:{value:(_vm.form.budidaya8),callback:function ($$v) {_vm.$set(_vm.form, "budidaya8", _vm._n($$v))},expression:"form.budidaya8"}},[_vm._v(" Antara 500 - 600 kg / 1 hp kincir ")]),_c('b-radio',{attrs:{"name":"kincir","native-value":"1"},model:{value:(_vm.form.budidaya8),callback:function ($$v) {_vm.$set(_vm.form, "budidaya8", _vm._n($$v))},expression:"form.budidaya8"}},[_vm._v(" Diatas 600 kg / 1 hp kincir ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Autofeeder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Autofeeder","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"autofeeder","native-value":"3"},model:{value:(_vm.form.budidaya9),callback:function ($$v) {_vm.$set(_vm.form, "budidaya9", _vm._n($$v))},expression:"form.budidaya9"}},[_vm._v(" 1 autofeeder per 1000 m2 ")]),_c('b-radio',{attrs:{"name":"autofeeder","native-value":"2"},model:{value:(_vm.form.budidaya9),callback:function ($$v) {_vm.$set(_vm.form, "budidaya9", _vm._n($$v))},expression:"form.budidaya9"}},[_vm._v(" 1 autofeeder per 1500 m2 ")]),_c('b-radio',{attrs:{"name":"autofeeder","native-value":"1"},model:{value:(_vm.form.budidaya9),callback:function ($$v) {_vm.$set(_vm.form, "budidaya9", _vm._n($$v))},expression:"form.budidaya9"}},[_vm._v(" 1 autofeeder diatas 1500 m2 ")])],1)])]}}])}),_c('ValidationProvider',{attrs:{"name":"Infrastruktur dan Aset Pendukung Bisnis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Infrastruktur dan Aset Pendukung Bisnis","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('b-radio',{attrs:{"name":"infrastruktur","native-value":"3"},model:{value:(_vm.form.budidaya10),callback:function ($$v) {_vm.$set(_vm.form, "budidaya10", _vm._n($$v))},expression:"form.budidaya10"}},[_vm._v(" Tersedia infrastruktur langsung maupun tidak langsung ")]),_c('b-radio',{attrs:{"name":"infrastruktur","native-value":"2"},model:{value:(_vm.form.budidaya10),callback:function ($$v) {_vm.$set(_vm.form, "budidaya10", _vm._n($$v))},expression:"form.budidaya10"}},[_vm._v(" Tersedia infrastruktur langsung, tapi tidak tersedia infrastruktur tidak langsung. Atau sebaliknya. ")]),_c('b-radio',{attrs:{"name":"infrastruktur","native-value":"1"},model:{value:(_vm.form.budidaya10),callback:function ($$v) {_vm.$set(_vm.form, "budidaya10", _vm._n($$v))},expression:"form.budidaya10"}},[_vm._v(" Tidak tersedia infrastruktur pendukung sama sekali ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="farm-info" v-if="showFarmInfo">
      <div class="card">
        <div class="card-content px-3 py-2">
          <div v-if="!farmInfoLoading">
            <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
              <h4 class="has-text-weight-bold mb-0">{{ farmInfo.name }}</h4>
              <div @click="showFarmInfo = false">
                <b-icon class="cursor-pointer has-text-grey" icon="close"/>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Owner</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.username : farmInfo.user_id }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Phone Number</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.phone : '' }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Email</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.email : '' }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Total Block</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.block_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Total Pond</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.pond_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">Total User</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.user_count }}</span>
              </div>
            </div>
          </div>
          <div class="loader my-2 ml-2" style="height: 25px; width: 25px;" v-else></div>
        </div>
      </div>
    </div>

    <card-component>
      <div class="is-flex mb-3 is-justify-content-space-between is-align-items-center">
        <div class="is-flex">
          Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ farm_data.length }}</span>
        </div>

        <div class="has-text-right">
          <b-button type="is-primary" @click="exportToCsv">Export to CSV</b-button>
        </div>
      </div>
        <b-table
        checkable
        :checked-rows.sync="checkedFarms"
        :loading="farms.loading"
        :paginated="paginated"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="name"
        :data='farm_data'>

        <b-table-column label="Farm Name" field="name" sortable v-slot="props" searchable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="City" field="city" sortable v-slot="props" searchable>
          {{ props.row.city }}
        </b-table-column>
        <b-table-column label="Location" field="location" sortable v-slot="props">
          {{ props.row.location }}
        </b-table-column>
        <b-table-column label="Created At" field="created_at" sortable v-slot="props" searchable>
          {{ $dateFormat(props.row.created_at) }}
          {{ $timeFormat(props.row.created_at) }}
        </b-table-column>
        <b-table-column label="Expired Date" field="expired_at" sortable v-slot="props" searchable>
          <div v-if="props.row.expired_at">
            {{ $dateFormat(props.row.expired_at) }}
            {{ $timeFormat(props.row.expired_at) }}
          </div>
          <div v-else>-</div>
        </b-table-column>
        <b-table-column label="Subscription Type" field="days" sortable v-slot="props" searchable>
          {{ props.row.days }} days
        </b-table-column>
        <b-table-column label="Action" v-slot="props">
          <div class="is-flex is-flex-direction-column">
            <b-button type="is-primary" @click="handleOpenDetails(props.row)" class="mb-2">Farm Detail</b-button>
            <!--          <b-button type="is-success" @click="updateSubscription(props.row)" class="mb-2">Update Subscription</b-button>-->
            <!--          <b-button type="is-danger" @click="destroyFarm(props.row.farm_id, props.row.name)">Complete Delete</b-button>-->
          </div>
        </b-table-column>

        <template #detail="props">
          <div class="columns" v-if="!props.row.infoLoading">
            <div class="column" v-if="props.row.user">
              <div class="columns is-gapless mb-2">
                <div class="column">Owner</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.username : props.row.user_id }}
                  </span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Phone Number</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.phone : '' }}
                  </span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Email</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.email : '' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column" v-if="props.row.info">
              <div class="columns is-gapless mb-2">
                <div class="column">Total Block</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.block_count }}</span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Total Pond</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.pond_count }}</span>
                </div>
              </div>
              <div class="columns is-gapless">
                <div class="column">Total User</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.user_count }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="loader" style="height: 25px; width: 25px;margin: 0 auto" v-else></div>

        </template>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="farms.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's there&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </card-component>


    <!--    <b-modal-->
    <!--      v-model="subscriptionModal"-->
    <!--      has-modal-card-->
    <!--      :destroy-on-hide="true"-->
    <!--      aria-role="dialog"-->
    <!--      aria-modal>-->
    <!--      <template #default="props">-->
    <!--        <update-subscription @save="getFarms" @close="props.close" :farm="selectedFarm"/>-->
    <!--      </template>-->
    <!--    </b-modal>-->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import CardComponent from "@/components/CardComponent";

export default {
  name: 'FinanceFarmTable',
  components: {CardComponent},
  data() {
    return {
      paginated: true,
      perPage: 10,
      farm_type: 0,
      checkedFarms: [],
      subscriptionModal: false,
      selectedFarm: null,

      farmInfo: null,
      showFarmInfo: false,
      farmInfoLoading: false,
    }
  },
  computed: {
    ...mapState('finance', [
      'farms',
    ]),
    ...mapState('user', [
      'users',
    ]),

    farm_data() {
      let data = this.farms.data

      let now = this.$moment()

      if (this.farm_type === 1) data = data.filter(e => e.expired_at > now.valueOf())
      if (this.farm_type === 2) data = data.filter(e => e.expired_at <= now.valueOf())
      if (this.farm_type === 3) data = data.filter(e => -now.diff(this.$moment(e.expired_at), 'days') <= 7 && -now.diff(this.$moment(e.expired_at), 'days') >= 0)

      return data
    },
  },
  created() {
    this.getUsers()
    this.getMentoredFarms()
  },
  methods: {
    ...mapActions('finance', [
      'getMentoredFarms',
    ]),

    ...mapActions('user', [
      'getUsers',
    ]),

    ...mapActions('farm', [
      'getFarmInfo',
    ]),

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    async handleOpenDetails(farm) {
      this.showFarmInfo = true
      this.farmInfoLoading = true

      this.farmInfo = farm

      if (farm) {
        farm.user = this.users.data.find(e => e.user_id === farm.user_id)

        farm.info = {
          block_count: 0,
          pond_count: 0,
          user_count: 0,
        }

        new Promise((resolve => {
          this.getFarmInfo(farm.farm_id).then(res => {
            resolve({
              farm_id: farm.farm_id,
              data: res
            })
          })
        })).then(res => {
          this.farmInfo.info = res.data
          this.farmInfoLoading = false
        })
      }

    },


    exportToCsv() {
      let users = this.users.data

      let data = this.farm_data.sort((a, b) => a.name.localeCompare(b.name, undefined, {
        sensitivity: 'base',
        numeric: true
      })).map(e => {
        let user = users.find(f => f.user_id === e.user_id)
        return [
          e.name,
          e.city.replace(/,/ig, ' '),
          e.location.replace(';', ','),
          this.$dateFormat(e.created_at, 'YYYY-MM-DD HH:mm'),
          this.$dateFormat(e.expired_at, 'YYYY-MM-DD HH:mm'),
          e.days + ' days',
          user ? user.username : '-',
          user ? user.email : '-',
          user ? user.phone + ';' : '-',
        ]
      })

      let csvContent = "data:text/csv;charset=utf-8,";

      data = [
        ['Name', 'City', 'Latitude', 'Longitude', 'Created At', 'Expired Date', 'Subscription Type', 'User Name', 'Email', 'Phone'],
        ...data
      ]

      data.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Sgara Farm - " + this.$dateFormat() + '.csv');
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  }
}
</script>

<style lang="scss" scoped>

.farm-info {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 100;
  width: 600px;
  opacity: .86;
  transition: opacity .15s;

  &:hover {
    opacity: 1;
  }
}

</style>

<template>
  <div class="credit-scoring">
    <div class="mb-4" v-if="!showScore">
      <card-component>
        <b-steps size="is-medium" v-model="currentStep">
          <b-step-item label="Select Farm" step="1">
            <credit-scoring-farm-name @update-data="value=> updateData(0, value)" ref="select-farm"/>
          </b-step-item>
          <b-step-item label="Budidaya" step="2">
            <credit-scoring-budidaya @update-data="value=> updateData(1, value)" ref="budidaya" :data="stepData[0].value.data"/>
          </b-step-item>
          <b-step-item label="Manajemen Bisnis" step="3">
            <credit-scoring-manajemen-bisnis @update-data="value=> updateData(2, value)" ref="manajemen-bisnis"/>
          </b-step-item>
          <b-step-item label="Eksternal" step="4">
            <credit-scoring-eksternal @update-data="value=> updateData(3, value)" ref="eksternal"/>
          </b-step-item>
          <b-step-item label="Rekanan dan Mitra" step="5">
            <credit-scoring-rekanan-mitra @update-data="value=> updateData(4, value)" ref="rekanan-mitra"/>
          </b-step-item>

          <!--            <b-step-item :label="`${$t('add')} ${$t('farm.pond')}`" step="2">-->
          <!--              <add-pond @update-data="value=> updateData(1, value)" ref="add-pond"/>-->
          <!--            </b-step-item>-->

          <template
            slot="navigation"
            slot-scope="{previous, next}">
            <div class="is-flex is-justify-content-space-between px-4">
              <b-button
                class="has-text-weight-bold"
                outlined
                type="is-dark"
                icon-left="arrow-left"
                :disabled="previous.disabled"
                @click.prevent="previous.action">
                Previous
              </b-button>
              <b-button
                class="has-text-weight-bold"
                outlined
                type="is-dark"
                icon-right="arrow-right"
                @click.prevent="nextStep(next.action)">
                {{ isLatestStep ? 'Finish' : 'Next' }}
              </b-button>
            </div>
          </template>
        </b-steps>
      </card-component>
    </div>
    <div v-else>
      <card-component>
        <h2 class="has-text-weight-bold has-text-centered is-size-4">{{ stepData[0].value.selected.name }}</h2>
        <gauge-chart :series="series" :options="chartOptions"/>
      </card-component>
    </div>
  </div>
</template>

<script>
import GaugeChart from "@/components/Charts/GaugeChart";
import CardComponent from "@/components/CardComponent";
import {mapActions, mapState} from "vuex";
import CreditScoringFarmName from "@/components/Finance/CreditScoring/CreditScoringFarmName";
import CreditScoringBudidaya from "@/components/Finance/CreditScoring/CreditScoringBudidaya";
import CreditScoringManajemenBisnis from "@/components/Finance/CreditScoring/CreditScoringManajemenBisnis";
import CreditScoringRekananMitra from "@/components/Finance/CreditScoring/CreditScoringRekananMitra";
import CreditScoringEksternal from "@/components/Finance/CreditScoring/CreditScoringEksternal";

export default {
  name: "FinanceCreditScoring",
  components: {
    CreditScoringEksternal,
    CreditScoringRekananMitra,
    CreditScoringManajemenBisnis,
    CreditScoringBudidaya, CreditScoringFarmName, CardComponent,
    GaugeChart,
  },
  data: () => {
    return {
      currentStep: 0,
      stepData: [
        {
          name: 'select-farm',
          canContinue: false,
          value: {
            data: [],
          },
        },
        {
          name: 'budidaya',
          canContinue: false,
        },
        {
          name: 'manajemen-bisnis',
          canContinue: true,
        },
        {
          name: 'eksternal',
          canContinue: true,
        },
        {
          name: 'rekanan-mitri',
          canContinue: true,
        },
      ],
      data: [],
      series: [76],
      chartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: [''],
      },
      showScore: false,
    }
  },
  computed: {
    isLatestStep() {
      return this.currentStep === this.stepData.length - 1
    },
  },
  created() {
    this.getFarms()
  },
  methods: {
    ...mapActions('farm', [
      'getFarms',
    ]),

    ...mapActions('finance', [
      'calculateCreditScoring',
    ]),

    updateData(index, value) {
      this.stepData[index].canContinue = value.canContinue
      this.stepData[index].value = value.data
    },

    async nextStep(action) {
      let next = false

      next = this.$refs[this.stepData[this.currentStep].name] ? await this.$refs[this.stepData[this.currentStep].name].canContinue() : this.stepData[this.currentStep].canContinue

      if (next) {
        if (!this.isLatestStep) action()
        else {
          let payload = {
            ...this.stepData[1].value,
            ...this.stepData[2].value,
            ...this.stepData[3].value,
            ...this.stepData[4].value,
          }

          this.$loading()
          this.calculateCreditScoring(payload).then(res => {
            if (!res.error && res.data) {
              this.series = [res.data.total]
              this.showScore = true
            }
          }).finally(() => {
            this.$loading(false)
          })
        }
      }
    },

    submit() {

    }
  },
}
</script>

<style lang="scss">
.credit-scoring {
  .b-radio {
    margin-bottom: 1rem;
  }
}
</style>
